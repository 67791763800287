import React, { useEffect } from "react"
import { Router } from "@reach/router"
import { connect } from "react-redux"
import loadable from "@loadable/component"

import { setAdmin } from "../actions/admin/setAdmin"
import Loading from "../components/Loading"
import AdminNoMatch from "../components/Admin/AdminNoMatch"

const AdminPage = loadable(() => import("../components/Admin/AdminPage"), {
  fallback: <Loading loading={true} />,
})
const Login = loadable(() => import("../components/Admin/Login"), {
  fallback: <Loading loading={true} />,
})
const ProtectedRoute = loadable(
  () => import("../components/Admin/ProtectedRoute"),
  { fallback: <Loading loading={true} /> }
)
const ManageStores = loadable(
  () => import("../components/Admin/ManageStores/ManageStores"),
  { fallback: <Loading loading={true} /> }
)
const EndOfDay = loadable(
  () => import("../components/Admin/ManageStores/EndOfDay"),
  { fallback: <Loading loading={true} /> }
)
const PickupSchedule = loadable(
  () => import("../components/Admin/PickupSchedule/PickupSchedule"),
  { fallback: <Loading loading={true} /> }
)
const ProductsPreorder = loadable(
  () => import("../components/Admin/ProductsPreorder/ProductsPreorder"),
  { fallback: <Loading loading={true} /> }
)
const ProductsFromCollection = loadable(
  () => import("../components/Admin/ProductsPreorder/ProductsFromCollection"),
  { fallback: <Loading loading={true} /> }
)
const OrdersReportPage = loadable(
  () => import("../components/Admin/Reports/OrdersReportPage"),
  { fallback: <Loading loading={true} /> }
)
const CreateOrderPage = loadable(
  () => import("../components/Admin/CreateOrder/CreateOrderPage"),
  { fallback: <Loading loading={true} /> }
)

const Admin = ({ dispatch, locations }) => {
  useEffect(() => {
    dispatch(setAdmin())
    return () => dispatch(setAdmin())
  }, [])

  if (Object.keys(locations).length === 0) {
    return <Loading loading={true} />
  }

  return (
    <Router basepath="/admin">
      <Login path="/" />
      <ProtectedRoute component={AdminPage} path="/main" />
      <ProtectedRoute component={ManageStores} path="/store/manage" />
      <ProtectedRoute component={EndOfDay} path="/store/end_of_day" />
      <ProtectedRoute component={PickupSchedule} path="/pickups/:storeID" />
      <ProtectedRoute component={ProductsPreorder} path="/products/preorder" />
      <ProtectedRoute
        component={ProductsFromCollection}
        path="/products/preorder/:collectionHandle"
      />
      <ProtectedRoute component={OrdersReportPage} path="/orders/report" />
      <ProtectedRoute component={CreateOrderPage} path="/orders/create" />
      <AdminNoMatch path="*" />
    </Router>
  )
}

const mapStateToProps = ({ stores: { locations } }) => ({ locations })

export default connect(mapStateToProps)(Admin)
